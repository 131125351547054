/*eslint-disable*/
//apis Connection

const commonapi = {
  //Product Live Apis>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  getAllproductsEng: `/en/get-products`,
  getAllproductsArb: `/ar/get-products`,
  getbyidproductsEng: `en/get-product/`,
  getbyidproductsArb: `ar/get-product/`,
  getCategoryIdbyProductsEng: `en/get-products-by-category/`,
  getCategoryIdbyProductsArb: `ar/get-products-by-category/`,

  //categorylive>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  getAllcategoryEng: `/en/get-categories`,
  getAllcategoryArb: `/ar/get-categories`,

  //Banner apis>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  BannerGetall: `/get-settings`,

  // cart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  CreateCart: `/add-to-cart`,
  GetAllCartEng: `/en/get-cart/`,
  GetAllCartArb: `/ar/get-cart/`,

  DeleteCartItems: `/cart-item-remove`,
  UpdateCartQty: `/update-cart-quantity`,
  //Orderapis>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  Order: `/Order`,

  //About-us>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  getAllAboutUsEng: `/en/get-about-us`,
  getAllAboutUsArb: `/ar/get-about-us`,

  //Coupons>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  getAllCoupons: `/apply-coupon`,
  //Get_Setting>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  getAllSettings: `/get-settings`,
  getAllSettingsArb: `/get-settings/ar`,
  getAllSettingsEng: `/get-settings/en`,

  //  GetCheckoutPage>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  CreateOrder: `/create-order`,
  Orderid: `/en/get-orders-by-order-no/`,

  //  GetContactUsPage>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  ContactUs: `/sendContactUs`,
  search: `/main-site/search/`,

  countries: `/countries`,
};

export default commonapi;
