/*eslint-disable*/

import React, { useState, useEffect, useRef, Suspense } from "react";
import { Tabs, Tab } from "react-bootstrap";
import api from "../../Config/api";
import commonApi from "../../Config/CommonApi";
import styled from 'styled-components';
import shoprr_logo from "../../images/Shoprr_1.svg";
import arrow_drop_down from "../../images/arrow_drop_down.png";

const Container = styled.div`
body {
  overflow-x: hidden;
}

.shopImg {
  width: 160px;
  height: 140px;
  float: left;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.loading{
  width: 100%;
  padding-bottom:20px;
}

a {
  text-decoration: none;
}

.form {
  display: flex;
  align-self: center;
  width: 70%;
  height: 50px;
  margin-left: 30px;
  border: 0.25px solid rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  padding: 5px;
}

.form i {
  color: #000;
  font-size: 15px;
  padding: 10px;
}

.shoprrHeader {
  display: flex;
  align-content: center;
}

.no-results-alert {
  padding: 15px 20px;
  color: #721c24; 
  font-size: 16px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 68vh;
}

.shoprr {
  display: flex;
  align-items: center;
}

.shoprr img:hover{
  background: none;
}

.shoprr a:hover{
  background: none;
}

div h1 {
  align-content: center;
}

.inp {
  outline: none;
  width: 750px;
  border-radius: 5px;
  border: none;
}

.toggleIcon {
  color: black;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

i {
  color: rgb(124, 121, 121);
}

.main {
  display: flex;
  height: 100%;
}

a:focus,
a:hover {
  background: rgba(0, 0, 0, 0.12);
  text-decoration: none;
  font-weight: 700;
}

.menuContainer {
  width: 16%;
  top: 0;
  position: fixed;
  right: 0;
  transition: all 0.5s;
  z-index: 9999;
  background: white;
  height: 100%;
}

.dropdownContainer {
  display: none;
  position: absolute;
  background: #fff;
  border-radius: 5px;
}

.dropdownContainer::before {
  content: "";
  border-top: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 0;
  top: -20px;
}

.dropdownContainer a {
  display: block;
  font-size: 14px;
  color: #333;
  padding: 8px 10px;
  font-family: arial, sans-serif;
  text-decoration: none;
}

.dropdownContainer a:hover {
  background: #333;
  color: #fff;
}

.dropdownContainer a:hover:nth-of-type(1) {
  border-radius: 5px 5px 0 0;
}

.dropdownContainer a:hover:last-child {
  border-radius: 0 0 5px 5px;
  background: #333;
}

.showMenu {
  width: 400px;
  height: 100%;
  display: inline-block;
  background: #fff;
  top: 0;
  transition: all 0.5s;
  z-index: 10000;
}

.menuBar {
  margin-top: 85px;
}

.menuWrapper {
  height: 100%;
  width: 100%;
}

.navClick {
  position: fixed;
  height: 87px;
  cursor: pointer;
  margin-right: 50px;
  float: right;
  transition: all 0.5s;
  z-index: 20000;
  right: 0;
  top: 8px;
}

.navClick .toggleIcon {
  background-color: #fff;
  color: #000;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.5s;
}

.navClick .toggleIcon.active,
.navClick .toggleIcon:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.showSS {
  display: inline-block;
}

.showXmark {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  transition: background-color 0.5s;
}

.xmarkIcon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -50px;
  border-radius: 50%;
}

.xmarkIcon:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.showIcon {
  z-index: 100000;
  position: relative;
}

.shoprrText {
  font-weight: bold;
  margin: auto 10px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

ul > li {
  text-align: left;
}

ul > li > a {
  color: rgba(68, 68, 68, 0.99);
  padding: 15px;
  display: block;
  text-decoration: none;
  transition: all 0.5;
  font-family: arial, sans-serif;
  font-size: 16px;
}

ul > li > a:hover {
  color: #000;
}

ul > li > a:hover {
  color: #000;
  text-decoration: none;
  transition: all 0.5;
  background: rgba(0, 0, 0, 0.12);
}

.navClick .bar1,
.navClick .bar2,
.navClick .bar3 {
  width: 30px;
  height: 4px;
  background: #0bf;
  margin: 7px 0;
  transition: 0.4s;
  display: block;
}

.i-xMark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.menuBack {
  width: 100%;
  display: flex;
  justify-content: right;
  border-radius: 50%;
  cursor: pointer;
  padding-right: 40px;
  position: absolute;
  top: 30px;
}

.menuBack:hover::after {
  border-right: 3px solid #333;
  border-bottom: 3px solid #333;
}

.menuBack:hover {
  color: #333;
}

.dropdowntoggleSS {
  position: relative;
}

.removeInp{
  // margin-top: 2%;
}
.dropdowntoggleSS::after {
  content: "";
  position: absolute;
  border-left: 7px solid transparent;
  border-top: 7px solid #333;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  width: 0;
  margin-left: 6px;
  top: 22px;
}

.dropdowntoggleSS:hover::after {
  border-top: 7px solid #333;
}

.Box {
  display: flex;
  flex-wrap: wrap;
  margin-right: 15px;
  width: 560px;
}

.tab-content > .active {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.cardBody {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}

.cardContent {
  height: 140px;
}

.col-md-4 {
  width: 23%;
}

.cardText {
  display: flex;
  padding: 0;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0;
}

.chevronRight {
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-right: -110px;
  color: black;
  transition: all 0.5;
}

.see-more-button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 40px;
  padding: 13px 60px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.see-more-div{
  margin-top: 40px;
  padding-bottom: 75px;
}

.see-more-button:hover {
  background-color: #f9f9f9;
  border-color: #999;
}

.country-filter-div {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px;
  text-align: center;
  z-index: 1;
}

.country-filter-select {
  border: 1px solid #ccc;
  border-radius: 40px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  text-align: center;
  -webkit-appearance: none;
  background-position-x: 75px !important;
  padding: 13px 30px 13px 22px;
  background-size: 28px !important;
}

.country-filter-select:hover {
  background-color: #f9f9f9;
  border-color: #999;
}

.chevronRight i {
  width: 50px;
  height: 50px;
  background-color: white;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s;
}

.chevronRight i:hover {
  background: rgba(0, 0, 0, 0.12);
}

.nav {
  padding-left: 15px;
}

.nav a {
  color: #333;
  font-size: 18px;
}

.mainContent {
  margin-left: 2%;
  margin-top: 1%;
  width: 100%;
  display: flex;
}

.nav-link:focus,
.nav-link:hover {
  color: #333;
}

li a {
  color: #333;
  text-decoration: none;
}

.wrapper {
  width: 100%;
  padding: 15px;
  width: 100%;
  text-align: center;
}

.tab-content {
  padding: 10px 20px;
}
.nav-tabs a {
  border: 0 !important;
  background-color: transparent !important;
}
.nav-tabs li.active {
  border-bottom: 2px solid #000;
}
.nav-tabs li {
  border-bottom: 2px solid transparent;
  --bs-nav-link-color: black;
}
.nav-tabs li a {
  width: 100%;
  text-align: center;
}
ul.nav.nav-tabs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}
.nav-tabs .nav-link{
  border: none;
}
.nav-tabs li button.active{
  border-bottom: 2px solid black;
}
.nav-tabs li {
  padding: 10px 10px 0px 10px;
  font-size: 18px;
  font-weight: 350;
}
.tabs-wrapper {
  position: relative;
  padding-bottom: 80px;
}
span.left,
span.right {
  position: absolute;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  border-radius: 50%;
  left: -50px;
  top: 8px;
  cursor: pointer;
  color: #ffffff;
}
span.right {
  right: -50px;
  left: unset;
}

.scroller-btn.inactive {
  opacity: 0.3;
  pointer-events: none;
}
.card-title{
  font-size: 16px;
}
.card-text{
  font-size: 14px;
}

@media only screen and (min-width: 321px) and (max-width: 970px) {
  .mainContent {
    flex-direction: column;
  }
  .form {
    width: 100%;
  }
}


@media only screen and (min-width: 321px) and (max-width: 768px) {
  .card-text{
    font-size: 14px;
  }
  .shop_address{
    font-size: 11px !important;
  }
  .card-title{
    font-size: 14px;
  }
  .main {
    display: flex;
    justify-content: center;
  }
  .see-more-div{
    align-content: center;
    flex: auto;
  }
  .country-filter-div{
    align-content: center;
    flex: auto;
  }
  .see-more-button{
    padding: 15px 50px
  }
  .form {
    width: 100%;
    margin: 0;
  }
  .showMenu {
    width: 340px;
    height: 100%;
  }
  .removeInp{
    margin-right: 7%;
  }
  .main .d-flex{
    padding: 2%;
  }
  .shoprrHeader {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .mainContent {
    margin-top: 4%;
    flex-direction: column;
  }
  .mainContent img{
    margin-bottom: 14%;
  }
  .navClick{
    margin-right: 10px;
    margin-top: 10px;
  }
  .Box {
    margin-right: 0;
    width: 100%;
  }
  .cardContent {
    width: 40%;
    margin-left: -15px;
    display: flex;
    flex-wrap: nowrap;
  }
  .cardBody{
    width: 100%;
    height: 130px;
    margin-bottom: 20px;
  }
  .tab-content{
    padding: 20px 0 0 0;
  }
  .cardText {
    margin-left: 0;
  }
  .shopImg {
    width: 80%;
    height: 140px;
    float: none !important;
  }

 @media only screen and (max-width: 1024px) {
    .menuContainer{
      display: block;
      position: fixed;
      width: 50%;
      background: white;
    }
  }

}

`;

function SearchResult(props) {
  const inputRef = useRef();
  const [searchValue, setSearchValue] = useState(props.searchValue);
  const [showMenu, setShowMenu] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showSS, setShowSS] = useState(false);
  const [products, setProducts] = useState(null);
  const [shops, setShops] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(props.selectedCountry);
  const [currentPage, setCurrentPage] = useState(12);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [showSeeMoreShop, setShowSeeMoreShop] = useState(false);
  const [showSeeMoreProduct, setShowSeeMoreProduct] = useState(false);

  const handleNavClick = () => {
    setShowMenu(!showMenu);
    setShowIcon(!showIcon);
  };

  const handleDropdowntoggleSSClick = () => {
    setShowSS(!showSS);
  };

  const disableAllSeeMore = () => {
    setShowSeeMoreShop(false);
    setShowSeeMoreProduct(false);
    setShowSeeMore(false);
  }

  const sendRequest = async (text) => {
    try {
        const response = await api.get(`${commonApi.search}${text}/${selectedCountry}/${currentPage}`);
        const { result } = response.data || {};
        if (result) {
          const response_products = typeof result.products !== 'undefined' ? result.products : [];
          const response_shops = typeof result.shops !== 'undefined' ? result.shops : [];
          setProducts(response_products.length == 0 ? null : response_products);
          setShops(response_shops.length == 0 ? null : response_shops);
          setShowSeeMoreShop(response_shops.length > 0);
          setShowSeeMoreProduct(response_products.length > 0);
          setShowSeeMore(response_shops.length > 0 || response_products.length > 0);
        } else {
            setProducts(null);
            setShops(null);
            disableAllSeeMore()
        }
    } catch (error) {
        console.error("Error occurred while fetching data:", error);
        setProducts(null);
        setShops(null);
        disableAllSeeMore()
    } finally {
        setLoading(false);
    }
  };

  const handleSearch = () => {
    setLoading(true);
    setProducts(null)
    setShops(null)
    resetCurrentPage()
    sendRequest(searchValue, currentPage)
    inputRef.current.blur(); 
  };

  const changeSearchValue = (event) => {
    setSearchValue(event.target.value)
  }

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      setLoading(true);
      setSearchValue(event.target.value);
      setProducts(null)
      setShops(null)
      resetCurrentPage()
      sendRequest(event.target.value)
      inputRef.current.blur(); 
    }
  };

  const handleClearInput = () => {
    setSearchValue('');
  };
  
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleSeeMore = () => {
    let sumCurrentPage = currentPage == 12 ? 24 : currentPage + 12
    api.get(commonApi.search + searchValue + '/' + selectedCountry + '/' + sumCurrentPage).then((res) => {
      if (res.data?.result) {
        const response_products = res.data.result?.products;
        const response_shops = res.data.result?.shops;
        const productsAreSame = JSON.stringify(response_products) === JSON.stringify(products);
        const shopsAreSame = JSON.stringify(response_shops) === JSON.stringify(shops);
        
        if(productsAreSame && shopsAreSame){
          disableAllSeeMore()
          setLoading(false);
          return;
        } 
        if (productsAreSame) {
          setShowSeeMoreProduct(false);
        } else if(shopsAreSame){
          setShowSeeMoreShop(false);
        }
        setProducts(response_products)
        setShops(response_shops)
        setCurrentPage(currentPage + 12);
      } else {
        // No result found
        setShops(null)
        setProducts(null)
        disableAllSeeMore()
      }
      setLoading(false);
    });
  };


  const resetCurrentPage = () => {
    setCurrentPage(12);
  };

  const goToLink = (event, url) => {
    event.preventDefault(); 
    window.open(url,'_blank'); 
  }

  useEffect(() => {
    setLoading(true);
    api.get(commonApi.countries).then((res) => {
      if (res.data?.result) {
        setCountries(res.data.result);
      } else {
        setCountries([]);
      }
    });

    api.get(commonApi.search + props.searchValue + '/' + selectedCountry + '/' + currentPage).then((res) => {
      if (res.data.result) {
        const products = res.data.result?.products
        const shops = res.data.result?.shops
        setProducts(products);
        setShops(shops);
        setShowSeeMore(shops?.length > 0 || products.length > 0);
        setShowSeeMoreProduct(products.length > 0);
        setShowSeeMoreShop(shops?.length > 0);
      } else {
        disableAllSeeMore()
        console.error("Error occurred while fetching data:", res);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Container>
        <div className="navClick" onClick={handleNavClick}>
          <span>
            <i className={`toggleIcon fa-solid fa-ellipsis-vertical fa-xl ${showIcon ? 'active fa-duotone fa-solid fa-xmark' : ''}`}></i>
          </span>
        </div>
        <div className={`menuWrap ${showMenu ? 'menuWrapper' : ''}`} >
          <div className="menuContainer" style={{ display: showMenu ? 'block' : 'none' }}>
            <div className="mainMenu">
              <div className="menuBack"></div>
            </div>
            <div className="menuBar">
              <ul>
                <li><a href="#">Sign in</a></li>
                <li><a href="#">Track Order</a></li>
                <li><a href="#">Privacy</a></li>
                <li><a href="#">About</a></li>
                <li><a href="#">Terms</a></li>
                <li>
                  <a href="#" className="dropdowntoggleSS" onClick={handleDropdowntoggleSSClick}>Arabic</a>
                  <div className={`dropdownContainer ${showSS ? 'showSS' : ''}`}>
                    <a href="#">HTML</a>
                    <a href="#">React</a>
                    <a href="#">Angular</a>
                    <a href="#">Latest Angular</a>
                    <a href="#">Javascript</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="main">
          <div className="mainContent">
            <div className="shoprrContent">
              <div className="shoprrHeader">
                <div className="shoprr">
                  <a href="/">
                    <img src={shoprr_logo} width='170'/>
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <form className="form">
                <i className="fa-solid fa-magnifying-glass" onClick={handleSearch}></i>
                <input
                  className="inp"
                  type="search"
                  name="search"
                  id="inp"
                  placeholder="search product, shop"
                  onChange={changeSearchValue}
                  onKeyDown={handleEnter}
                  value={searchValue}
                  inputMode="inputmode"
                  ref={inputRef} />
              </form>
              <div className="removeInp" onClick={handleClearInput} style={{ display: searchValue.length > 1 ? 'block' : 'none' }}>
                <i className="fa-duotone fa-solid fa-xmark fa-xl xmarkIcon"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="tabs-wrapper">
            <Suspense fallback={<div>Loading...</div>}>
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example scroller"
                className="nav nav-tabs"
              >
                <Tab eventKey="all" title="All">
                  {loading ? <div className="loading">Loading...<br /></div> : ''}
                  {shops &&
                    shops.map((data) => (
                      <div className="cardBody tab-pane in active" onClick={(event) => goToLink(event, data.shop_url)}>
                        <div className="Box card mb-3">
                          <div className="cardContent row g-0">
                            <div className="col-md-5">
                              <img
                                className="shopImg"
                                src={data && data.shop_logo}
                                alt=""
                              />
                            </div>
                            <div className="cardText col-md-5">
                              <div className="card-body">
                                <h4 className="card-title">{data && data.shop_name}</h4>
                                <p className="card-text">
                                  <span style={{ textDecorationLine: 'line-through' }}></span>
                                </p>
                                <p className="card-text">{data && data.shop_category}</p>
                                <p className="card-text shop_address">{data && data.shop_address}</p>
                              </div>
                              <a target="_blank" href={data.shop_url}><div className="chevronRight">
                                <i className="fa-solid fa-chevron-right fa-xl"></i>
                              </div></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {products &&
                    products.map((data) => (
                      <div className="cardBody tab-pane in active" onClick={(event) => goToLink(event, `${data.shop_url}/product/${data.id}`)}>
                        <div className="Box card mb-3">
                          <div className="cardContent row g-0">
                            <div className="col-md-5">
                              <img
                                className="shopImg"
                                src={data.product_image != null ? data.product_image : data.shop_logo}
                                alt=""
                              />
                            </div>
                            <div className="cardText col-md-5">
                              <div className="card-body">
                                <h4 className="card-title">{data && data.product_name}</h4>
                                <p className="card-text">{data && data.shop_name}</p>
                                <p className="card-text">
                                  <span>{data && data.sales_price}</span>
                                </p>
                              </div>
                              <a target="_blank" href={`${data.shop_url}/product/${data.id}`}><div className="chevronRight">
                                <i className="fa-solid fa-chevron-right fa-xl"></i>
                              </div></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-lg-12 see-more-div" onClick={handleSeeMore} style={{ display: showSeeMore && loading == false ? 'block' : 'none' }}>
                      <button className="see-more-button">See more</button>
                    </div>
                    <div className="alert no-results-alert" style={{ display: shops == null && products == null  && loading == false  ? 'block' : 'none' }}>
                      <span className="alert-message">No results found.</span>
                    </div>
                </Tab>
                <Tab eventKey="product" title="Product">
                  {loading ? <div className="loading">Loading...<br /></div> : ''}
                  {products &&
                    products.map((data) => (
                      <div className="cardBody tab-pane in active" onClick={(event) => goToLink(event, `${data.shop_url}/product/${data.id}`)}>
                        <div className="Box card mb-3">
                          <div className="cardContent row g-0">
                            <div className="col-md-5">
                              <img
                                className="shopImg"
                                src={data.product_image != null ? data.product_image : data.shop_logo}
                                alt=""
                              />
                            </div>
                            <div className="cardText col-md-5">
                              <div className="card-body">
                                <h4 className="card-title">{data && data.product_name}</h4>
                                <p className="card-text">{data && data.shop_name}</p>
                                <p className="card-text">
                                  <span>{data && data.sales_price}</span>
                                </p>
                              </div>
                              <a target="_blank" href={`${data.shop_url}/product/${data.id}`}><div className="chevronRight">
                                <i className="fa-solid fa-chevron-right fa-xl"></i>
                              </div></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-lg-12 see-more-div" onClick={handleSeeMore} style={{ display: showSeeMoreProduct && loading == false ? 'block' : 'none' }}>
                      <button className="see-more-button">See more</button>
                    </div>
                    <div className="alert no-results-alert" style={{ display: products == null && loading == false ? 'block' : 'none' }}>
                      <span className="alert-message">No results found.</span>
                    </div>
                </Tab>
                <Tab eventKey="shop" title="Shop">
                  {loading ? <div className="loading">Loading...<br /></div> : ''}
                  {shops &&
                    shops.map((data) => (
                      <div className="cardBody tab-pane in active" onClick={(event) => goToLink(event, data.shop_url)}>
                        <div className="Box card mb-3">
                          <div className="cardContent row g-0">
                            <div className="col-md-5">
                              <img
                                className="shopImg"
                                src={data && data.shop_logo}
                                alt=""
                              />
                            </div>
                            <div className="cardText col-md-5">
                              <div className="card-body">
                                <h4 className="card-title">{data && data.shop_name}</h4>
                                <p className="card-text">
                                  <span style={{ textDecorationLine: 'line-through' }}></span>
                                </p>
                                <p className="card-text">{data && data.shop_category}</p>
                                <p className="card-text shop_address">{data && data.shop_address}</p>
                              </div>
                              <a target="_blank" href={data.shop_url}><div className="chevronRight">
                                <i className="fa-solid fa-chevron-right fa-xl"></i>
                              </div></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-lg-12 see-more-div" onClick={handleSeeMore} style={{ display: showSeeMoreShop && loading == false ? 'block' : 'none' }}>
                      <button className="see-more-button">See more</button>
                    </div>
                    <div className="alert no-results-alert" style={{ display: shops == null && loading == false ? 'block' : 'none' }}>
                      <span className="alert-message">No results found.</span>
                  </div>
                </Tab>
              </Tabs>
            </Suspense>
          </div>
        </div>
        <div className="col-lg-12 country-filter-div" align="center" style={{ display: countries.length > 0 && loading == false ? 'block' : 'none' }}>
          <select className="country-filter-select" onChange={handleCountryChange} style={{ background: `url(${arrow_drop_down}) no-repeat right` }}>
            {countries && countries.map((country) => (
              <option key={country.code} value={country.code} selected={selectedCountry === country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </Container>
    </>
  );
}

export default SearchResult;
