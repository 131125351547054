/*eslint-disable */
import React ,{useState,useEffect} from 'react'
import api from '../../Config/api'
import commonApi from '../../Config/CommonApi'
import {useParams,Link } from 'react-router-dom'
function OrderDetails() {
    const [checkordermobi, setcheckordermobi] = useState([]);
    const [checkordermobi1, setcheckordermobi1] = useState("");
    const [Settings, setSettings] = useState("");
  
    const checkOutClose3 =() => setcheckoutshow3(false);

    let { id ,id2 } = useParams();

    const Homepage =()=>{
      navigate(`/`);
    }

  const getAllFunction = () => {
    api
    .get(commonApi.Orderid + `${id}/${id2}`)
    .then((res) => {
     
      let data = res.data.result.orders[0].order_details;
      let data1 = res.data.result.orders[0];

      setcheckordermobi(data);
      setcheckordermobi1(data1);
     
      if (res.data.success === true) {
        setcheckoutshow1(false);
        setcheckoutshow2(true);
      }
    });
   
    api.get(commonApi.getAllSettings).then((res) => {
      let data = res.data.result;
      setSettings(data);
    });
  }
  useEffect(() => {
    getAllFunction();
  }, []);

  return (
    <>
     <div className="myprod_sec">
            <div
              className="my_prod_inside"
              style={{
                maxWidth: "600px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div className="myprod_header" style={{ background: "#fff" }}>
                <h5>
                  {" "}
                  Order ID #{" "}
                  <b>{checkordermobi1 && checkordermobi1.order_no} </b>
                  <span className="hideModule">
                    {" "}
                    <i
                      onClick={checkOutClose3}
                      className="fa-solid fa-xmark"
                    ></i>{" "}
                  </span>{" "}
                </h5>
              </div>
              <div className="myprod_body">
                <div className="table_outer">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td> SN </td>
                        <td> Image </td>
                        <td> Item Name </td>
                        <td> Item Price </td>

                        <td> Qty </td>
                        <td> Total </td>

                      </tr>
                    </thead>
                    <tbody>
                      {checkordermobi &&
                        checkordermobi.map((data ,index) => {
                          if (localStorage.getItem("Arab")) {
                            return (
                              <>
                                <tr>
                                  <td>{index +1}</td>

                                  <td>
                                    <img
                                      src={
                                        data &&
                                        data.product.product_images[0]
                                          .vf_product_image_path
                                      }
                                    />
                                  </td>
                                  <td>
                                    {data && data.product.product_name_ar}

                                    <b>{data && data.product_variant && data.product_variant.variant_name_ar ? <>Variant : </>:null}{data && data.product_variant && data.product_variant.variant_name_ar}</b>
                                    <b>{data && data.addons && data.addons.addon_name_ar ? <>Addons : </>:null}{data && data.addons && data.addons.addon_name_ar}</b>



                                  </td>
                                  <td>{Settings && Settings.currency.code} {data && data.amount} $</td>
                                  <td>{data && data.quantity}</td>
                                  <td>{data.amount * data.quantity}</td>

                                </tr>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <tr>
                                <td>{index +1}</td>
                                  <td>
                                    <img
                                      src={
                                       `/admin/images/product/${data &&
                                        data.product.product_images[0].path}`
                                      }
                                          
                                      
                                    />{" "}
                                  </td>
                                  <td>
                                    {data && data.product.product_name_en}<br></br>
                                    <b>{data && data.product_variant && data.product_variant.variant_name_en ? <>Variant : </>:null}{data && data.product_variant && data.product_variant.variant_name_en}</b>
                                    <b>{data && data.addons && data.addons.addon_name_en ? <>Addons : </>:null}{data && data.addons && data.addons.addon_name_en}</b>

                                  </td>
                                  <td>{Settings && Settings.currency.code} {data && data.amount} </td>
                                  <td>{data && data.quantity}</td>
                                  <td>{Settings && Settings.currency.code} {data.amount * data.quantity}</td>


                                </tr>
                              </>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <ul className="prod_total">
                      {/* <li>
                        {" "}
                        Qty <span> {OrderidData}</span>{" "}
                      </li> */}
                      <li>
                        {" "}
                        Sub Total{" "}
                        <span>
                          {" "}
                          {Settings && Settings.currency.code} {checkordermobi1 &&
                            checkordermobi1.total_amount +
                              checkordermobi1.discount -  checkordermobi1.tax_amount }
                        
                        </span>{" "}
                      </li>
                      
                      <li>
                        {" "}
                        {checkordermobi1.discount ? (
                          <> Discount :   <span>{Settings && Settings.currency.code} {checkordermobi1 && checkordermobi1.discount}
                           
                        </span></>
                        ) : null}{" "}
                       
                      </li>
                      <li>
                        {" "}
                        {checkordermobi1.tax_amount ? (
                          <>    Tax Amount  : <span>{Settings && Settings.currency.code}  {checkordermobi1 && checkordermobi1.tax_amount} </span> </>
                        ) : null}{" "}
                      </li>
                      <li>
                        {" "}
                        Total{" "}
                        <span>
                         <b> {Settings && Settings.currency.code} {checkordermobi1 && checkordermobi1.total_amount} </b> {" "}
                        </span>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-6">
                  <Link to="/">
         
                    <button
                      style={{ float: "left" }}
                      // onClick={()=>Homepage()}
                      id="go_backmodal"
                      className="checkBtns backBtns"
                      type="button"
                      >
                      {" "}
                      Close{" "}
                    </button>
                        </Link>
                  </div>
                  <div className="col-md-6 col-6">

                  <Link to="/">

                    <button
                      onClick={()=>Homepage()}
                      // id="lastClose"
                      className="checkBtns"
                      type="button"
                    >
                      {" "}
                      <i className="fa-solid fa-arrow-rotate-right"></i> 
                      Order Again{" "}
                    </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </>
  )
}
export default OrderDetails