/*eslint-disable*/
import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/scrollbar";
import shoprr_logo from "../../images/Shoprr_1.svg";
import arrow_drop_down from "../../images/arrow_drop_down.png";
import styled from 'styled-components';
import api from "../../Config/api";
import commonApi from "../../Config/CommonApi";

const Container = styled.div`
  html,
body {
  position: relative;
  overflow-x: hidden;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.form {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 0.25px solid rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  padding: 5px;
}

.form i {
  padding: 10px;
}

.shoprrHeader {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 50px;
}

.shoprrHeader div {
  width: 100px;
}

div h1 {
  align-content: center;
}

.inp {
  outline: none;
  width: 750px;
  border-radius: 5px;
  border: none;
}

.toggleIcon {
  color: black;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

i {
  color: rgb(124, 121, 121);
}

.main {
  height: 100%;
}

.menuContainer {
  width: 16%;
  top: 0;
  position: absolute;
  right: 0;
  transition: all 0.5s;
}

.dropdownContainer {
  display: none;
  position: absolute;
  background: #fff;
  border-radius: 5px;
}

.dropdownContainer::before {
  content: "";
  border-top: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 0;
  top: -20px;
}

.dropdownContainer a {
  display: block;
  font-size: 14px;
  color: #333;
  padding: 8px 10px;
  font-family: arial, sans-serif;
  text-decoration: none;
}

.dropdownContainer a:hover {
  background: #333;
  color: #fff;
}

.dropdownContainer a:hover:nth-of-type(1) {
  border-radius: 5px 5px 0 0;
}

.dropdownContainer a:hover:last-child {
  border-radius: 0 0 5px 5px;
  background: #333;
}

.showSS {
  display: inline-block;
}

.showMenu {
  width: 400px;
  height: 100%;
  display: inline-block;
  background: #fff;
  top: 0;
  transition: all 0.5s;
  z-index: 10000;
}

.menuBar {
  margin-top: 85px;
}

.menuWrapper {
  height: 100%;
  width: 100%;
}

.navClick {
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  right: 4%;
  float: right;
  z-index: 9999;
}

.showIcon {
  z-index: 100000;
}

ul {
  list-style: none;
  padding: 0;
}

ul > li {
  text-align: left;
}
.navClick.active,
.navClick:hover{
  background-color: rgba(0,0,0,0.12);
}
a:focus,
a:hover {
  background: rgba(0, 0, 0, 0.12);
  text-decoration: none;
  font-weight: 700;
}

ul > li > a {
  color: rgba(68, 68, 68, 0.99);
  padding: 15px;
  padding-left: 30px;
  display: block;
  text-decoration: none;
  transition: all 0.5;
  font-family: arial, sans-serif;
  font-size: 16px;
}

ul > li > a:hover {
  color: #000;
  transition: all 0.5;
  background: rgba(0, 0, 0, 0.12);
}

.navClick .bar1,
.navClick .bar2,
.navClick .bar3 {
  width: 30px;
  height: 4px;
  background: #0bf;
  margin: 7px 0;
  transition: 0.4s;
  display: block;
}

.i-xMark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sidebar {
  width: 100%;
  display: flex;
  justify-content: end;
}

.menuBack {
  width: 100%;
  display: flex;
  justify-content: right;
  border-radius: 50px;
  cursor: pointer;
  padding-right: 40px;
  position: absolute;
  top: 30px;
}

.menuBack:hover::after {
  border-right: 3px solid #0bf;
  border-bottom: 3px solid #0bf;
}

.menuBack:hover {
  color: #0bf;
}

.dropdowntoggleSS {
  position: relative;
}

.dropdowntoggleSS::after {
  content: "";
  position: absolute;
  border-left: 7px solid transparent;
  border-top: 7px solid #333;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  width: 0;
  margin-left: 6px;
  top: 22px;
}

.dropdowntoggleSS:hover::after {
  border-top: 7px solid #0bf;
}

@media only screen and (max-width: 500px) {
  .form {
    width: 85% !important;
    margin-left: 30px;
  }
}
@media only screen and (max-width: 800px) {
  .showMenu {
    width: 340px;
    height: 100%;
  }
  .navClick {
    right: 20px;
  }
  .inp {
    width: 100%;
  }
  .form {
    width: 85% !important;
    margin-left: 30px;
    margin-top: 1px !important;
  }

}

@media only screen and (min-width: 321px) and (max-width: 420px) {
  .form {
    width: 650px;
  }
}

@media only screen and (min-width: 768px) {
  .form {
    width: 750px;
  }
}

@media only screen and (max-width: 1024px) {
  .menuContainer{
    display: block;
    position: fixed;
    width: 50%;
    background: white;
  }
}

.country-filter-div {
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.country-filter-select {
  border: 1px solid #ccc;
  border-radius: 40px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  text-align: center;
  -webkit-appearance: none;
  background-position-x: 75px !important;
  padding: 13px 30px 13px 22px;
  background-size: 28px !important;
}
  
.country-filter-select:hover {
  background-color: #f9f9f9;
  border-color: #999;
}

.menuWrap {
  padding: 37px;
}
`;

function SearchForm(props) {

  const [showMenu, setShowMenu] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showSS, setShowSS] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    api.get(commonApi.countries).then((res) => {
      if (res.data.result) {
        setCountries(res.data.result)
        props.onChangeCountry(res.data.result[0].code);
      } else {
        setCountries([])
      }
    });
  }, []);

  const handleNavClick = () => {
    setShowMenu(!showMenu);
    setShowIcon(!showIcon);
  };

  const handleDropdowntoggleSSClick = () => {
    setShowSS(!showSS);
  };

  const handleSearch = () => {
    props.onChangeValue(searchValue);
  };
  
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value)
  };
  
  const handleChangeCountry = (event) => {
    props.onChangeCountry(event.target.value);
  };
  
  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault(); 
      props.onChangeValue(event.target.value);
    }
  };

  return (
    <>
      <Container>
        <div className={showMenu ? 'active navClick mt-4' : 'navClick mt-4'} onClick={handleNavClick}>
          <span>
            <i className={`toggleIcon fa-solid fa-ellipsis-vertical fa-xl ${showIcon ? 'fa-duotone fa-solid fa-xmark' : ''}`}></i>
          </span>
        </div>
        <div className={`menuWrap ${showMenu ? 'menuWrapper' : ''}`} >
          <div className="menuContainer" style={{ display: showMenu ? 'block' : 'none' }}>
            <div className="mainMenu">
              <div className="menuBack">
                {/* menu back content */}
              </div>
              <div className="menuBar">
                <ul>
                  <li><a href="#">Sign in</a></li>
                  <li><a href="#">Track Order</a></li>
                  <li><a href="#">Privacy</a></li>
                  <li><a href="#">About</a></li>
                  <li><a href="#">Terms</a></li>
                  <li>
                    <a href="#" className="dropdowntoggleSS" onClick={handleDropdowntoggleSSClick}>
                      Arabic
                    </a>
                    <div className={`dropdownContainer ${showSS ? 'showSS' : ''}`}>
                      <a href="#">HTML</a>
                      <a href="#">React</a>
                      <a href="#">Angular</a>
                      <a href="#">Latest Angular</a>
                      <a href="#">Javascript</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="main mt-5 d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column">
            <div>
              <div className="shoprrHeader mb-4">
                <img src={shoprr_logo} width='300'/>
              </div>
            </div>
            <form >

            <div className="mt-5 form">
              <i className="fa-solid fa-magnifying-glass" onClick={handleSearch}></i>
              <input
                className="inp"
                type="search"
                name="search"
                id="search"
                onChange={handleSearchChange}
                inputMode="inputmode"
                placeholder="search product, shop"
                onKeyDown={handleEnter}
              />
            </div>
            </form>
          </div>
        </div>
        <div className="col-lg-12 country-filter-div" align="center" style={{ display: countries.length > 0 ? 'block' : 'none' }}>
          <select className="country-filter-select" onChange={handleChangeCountry} style={{ background: `url(${arrow_drop_down}) no-repeat right` }}>
            {countries && countries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </Container>
    </>
  );
}

export default SearchForm;
