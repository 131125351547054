/*eslint-disable*/
import React, { useState } from "react";
import "swiper/css";
import "swiper/css/scrollbar";

import SearchResult from "./SearchResult";
import SearchForm from "./SearchForm";

function Search() {
  const [searchValue, setSearchValue] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showSearchResult, setShowSearchResult] = useState(false);
  
  const handleValueChange = (searchValue) => {
    setSearchValue(searchValue);
    setShowSearchResult(true)
  };
  
  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  return (
    <>
      {showSearchResult ? <SearchResult searchValue={searchValue} selectedCountry={selectedCountry}/> : <SearchForm onChangeValue={handleValueChange} onChangeCountry={handleCountryChange}/>}
    </>
  );
}

export default Search;
