/*eslint-disable*/

import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import OrderDetails from "./pages/components/OrderDetails";
import Home from './pages/components/Home'
import 'mapbox-gl/dist/mapbox-gl.css';
import MyOrders from "./pages/components/Myorder";

function App() {
  	return (
		<>
			<Router>
				<Routes>
					<Route path="/" element={<Home />}></Route>
					<Route exact path="/product/:product_id" element={<Home />}></Route>
					<Route exact path="/orderdetails/:id/:id2" element={<OrderDetails />}></Route>
					<Route exact path="/myorders/:id/:id2" element={<MyOrders />}></Route>
				</Routes>
			</Router>
    	</>
  	);
}

export default App;
